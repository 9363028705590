<template>
  <div class="home-top">
    <div>
      <span>选择国家：</span>
      <input
        type="text"
        placeholder="在此输入国家名称"
        v-model="queryInfo.nation"
      />
    </div>
    <div @click="country" class="country">
      <span v-for="item in nation" :key="item.id">{{ item.country }}</span>
    </div>
    <div class="weight">
      <span>预估重量：</span>
      <input
        type="text"
        ref="inputWeight"
        onkeyup="this.value=this.value.replace(/[^0.0-9-]+/,'');"
      />
      <span>KG</span>
    </div>
    <div class="btn" @click="queryPrice">
      <button>查询价格</button>
    </div>
    <!-- 寄送物品限制: -->
    <div class="text-1">
      <div>寄送物品限制:</div>
      <div>
        EMS可接的物品：普货,食品,品牌,药品,化妆品,光碟,原木制品等敏感货!其他超敏感物品;
        如液体、粉末、内置电池物品可走香港DHL、UPS、联邦、专线敏感等途径;
        完全不接的违禁物品：烟酒,白色粉末,纯电池,,动植物活体及标本,易燃易爆,刀具,枪支弹药等等!
        （以上途径出货物品类型包括但不限于，请知悉！
        具体请联系在线客服咨询，谢谢！）
      </div>
    </div>
    <!-- 什么是体积重: -->
    <div class="text-2">
      <div>什么是体积重:</div>
      <div>
        除EMS(60*60*60cm）以内不计算体积,其他途径均需计算体积！
        体积重指国际快递公司在计算收费重的时候,会根据体积计算体积重。当需寄递物品实际重量小而体积较大,运费需按材积标准收取。求取材积公式如下：规则物品：长（cm）×宽(cm)×高(cm)÷5000=A
        ；如果货物实重是B, 则A和B相比取大的那个做为计费重
        所以重量轻体积大的物品,比如毛绒玩具,要留意体积重。这类物品可以走EMS等不计体积途径更经济划算。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryInfo: {
        // 国家
        nation: "",
        // 重量
        weight: "",
      },
      nation: [],
    };
  },
  mounted() {
    // 获取国家数据
    this.getNation();
  },
  methods: {
    country(e) {
      if (e.target.innerText.length > 10) {
        return;
      }
      this.queryInfo.nation = e.target.innerText;
    },
    // 按钮所触发的函数
    queryPrice() {
      let value = this.queryInfo.nation;
      let nation = this.nation;
      let some = nation.some(function (item) {
        return item.country === value;
      });
      if (value === "") {
        return alert("请选择国家名称");
      } else if (!some) {
        return alert("请输入正确的国家");
      } else if (this.$refs.inputWeight.value == "") {
        return alert("重量必须大于等于0");
      } else {
        this.queryInfo.weight = this.$refs.inputWeight.value;

        // --------------------------------- 发请求
        this.axios
          .get("/channel/findByCountryAndWeight", { params: this.queryInfo })
          .then((res) => {
            if (res.data.code == 200) {
              // console.log(res);
              let CountryData = JSON.stringify(res.data.data);
              let queryInfo = JSON.stringify(this.queryInfo);
              sessionStorage.setItem("CountryData", CountryData);
              sessionStorage.setItem("queryInfo", queryInfo);
              this.$router.push({
                name: "query",
              });
            }
          });
        // ---------------------------------
      }
    },
    getNation() {
      this.axios.get("/channel/queryCountry").then((res) => {
        if (res.data.code == 200) {
          this.nation = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-top {
  margin-top: 2rem;
  font-size: 14px;
  input {
    width: 70%;
    padding: 0.2rem;
  }
  .country {
    margin-top: 0.3rem;
    width: 8rem;
    margin: 0.5rem auto;
    color: blue;
    span {
      margin-right: 0.3rem;
      display: inline-block;
      margin-bottom: 0.2rem;
    }
  }
  .weight {
    input {
      width: 60%;
      margin-right: 0.2rem;
    }
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #ededed;
  }
  .btn {
    margin-top: 0.3rem;
    button {
      width: 100%;
      padding: 0.3rem 0;
      background-color: #e8536a;
      border: none;
      border-radius: 0.25rem;
      color: #ffffff;
    }
  }
  .text-1 {
    color: #cb9764;
    margin-bottom: 1rem;
    & > div:nth-child(1) {
      margin-top: 0.25rem;
    }
    & > div:nth-child(2) {
      margin-top: 0.2rem;
      line-height: 170%;
    }
  }
  .text-2 {
    color: #cb9764;
    margin-bottom: 1rem;
    & > div:nth-child(1) {
      margin-top: 0.25rem;
    }
    & > div:nth-child(2) {
      margin-top: 0.2rem;
      line-height: 170%;
    }
  }
}
</style>
